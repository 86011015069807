@import "@/styles/_variables.scss";

















































































































































































































































































































































































































































































































































































































































































































.icon-image {
  width: 85px;
  height: 48px;
}
.trash-icon {
  height: 20px; 
  width: 20px;
  cursor: pointer;
}
.icon-drag {
  height: 20px; 
  width: 20px;
  cursor: move;
}
.uk-badge {
  height: 11px; 
  min-width: 11px;
}
.icon,.name,.type,.access, .back-link {
  cursor: pointer;
}
.new-collection-btn {
  padding: 0 20px;
}
.uk-table-divider>:first-child>tr:not(:first-child), .uk-table-divider>:not(:first-child)>tr, .uk-table-divider>tr:not(:first-child) {
  border-bottom: 1px solid var(--border-color);
}

.uk-text-truncate {

    color:var(--app-primary-color);
    cursor:pointer;

}
.uk-text-truncate:hover {
    
    color:var(--text-color);
    
}
